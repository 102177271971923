import {
  isOnlineTaskPhoto,
  isOnlineUserPhoto,
  OnlineTaskPhoto,
  OnlineUserPhoto,
  TaskPhoto,
  UserPhoto,
} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  getScaledWidthHeight,
  IMAGE_DEFAULT_MAX_SIZE,
  IMAGE_DISPLAY_HEIGHT,
  IMAGE_DISPLAY_WIDTH,
  THUMBNAIL_HEIGHT,
  THUMBNAIL_WIDTH,
  useCallWithFalse,
} from "@co-frontend-libs/utils";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  errorText: {
    defaultMessage: "Kunne ikke hente billede; forsøger igen om lidt",
    id: "photo-image.label.error-text",
  },
});

function instanceOfTaskPhoto(object: TaskPhoto | UserPhoto): object is TaskPhoto {
  return "task" in object;
}

function isOnlinePhoto(photo: TaskPhoto | UserPhoto): photo is OnlineTaskPhoto | OnlineUserPhoto {
  if (instanceOfTaskPhoto(photo)) {
    return isOnlineTaskPhoto(photo);
  } else {
    return isOnlineUserPhoto(photo);
  }
}

const sizeKeys = {
  display: [
    "displayWidth",
    "displayHeight",
    "displayDownload",
    IMAGE_DISPLAY_WIDTH,
    IMAGE_DISPLAY_HEIGHT,
  ],
  fullsize: [
    "originalWidth",
    "originalHeight",
    "fullsizeDownload",
    IMAGE_DEFAULT_MAX_SIZE,
    IMAGE_DEFAULT_MAX_SIZE,
  ],
  thumbnail: [
    "thumbnailWidth",
    "thumbnailHeight",
    "thumbnailDownload",
    THUMBNAIL_WIDTH,
    THUMBNAIL_HEIGHT,
  ],
} as const;

function getPhotoData(
  photo: TaskPhoto | UserPhoto,
  shareToken: string | null,
  size: keyof typeof sizeKeys,
  timestamp: number | null,
): {
  height: number;
  src: string;
  width: number;
} {
  const [widthKey, heightKey, downloadKey, maxWidth, maxHeight] = sizeKeys[size];
  if (isOnlinePhoto(photo)) {
    const width = photo[widthKey];
    const height = photo[heightKey];
    const src = `${photo[downloadKey]}?token=${shareToken}${
      timestamp ? `&timestamp=${timestamp}` : ""
    }`;
    return {height, src, width};
  } else {
    const data = photo._file_original;
    const {height, width} = getScaledWidthHeight(data.width, data.height, maxWidth, maxHeight);
    const src = data.data;
    return {height, src, width};
  }
}

interface PhotoImageProps {
  errorStyle?: React.CSSProperties;
  onClick: (event: React.MouseEvent) => void;
  photo: TaskPhoto | UserPhoto;
  shareToken: string | null;
  size: "display" | "fullsize" | "thumbnail";
}

const image: React.CSSProperties = {
  borderRadius: "5px",
  objectFit: "contain",
};

export const PhotoImage = React.memo(function PhotoImage(props: PhotoImageProps) {
  const {errorStyle, onClick, photo, shareToken, size} = props;

  const [error, setError] = useState(false);
  const setErrorFalse = useCallWithFalse(setError);
  const [timestamp, setTimestamp] = useState<number | null>(null);

  const handleError = useCallback(() => {
    setError(true);
    const timestampTimeoutSeconds = 30;
    setTimeout(() => {
      const newTimestamp = new Date().valueOf();
      setTimestamp(newTimestamp);
    }, timestampTimeoutSeconds * SECOND_MILLISECONDS);
  }, []);

  const {formatMessage} = useIntl();

  const photoData = getPhotoData(photo, shareToken, size, timestamp);
  const {src} = photoData;

  let errorText: React.JSX.Element | undefined;
  if (error) {
    errorText = <span style={errorStyle || {}}>{formatMessage(messages.errorText)}</span>;
  }

  return (
    <>
      <img
        alt=""
        onClick={onClick}
        onError={handleError}
        onLoad={setErrorFalse}
        src={src}
        style={image}
      />

      {errorText}
    </>
  );
});
