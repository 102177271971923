import type {AnyFunction} from "ts-essentials";
import {SyncedResourceHandleKey, SyncedResourceName} from "integration-constants";
import {kebabCase} from "lodash";
import {Branded} from "shared-types";

export type ApiUrl = Branded<string, "ApiUrl">;

const asApiUrl = (str: string): ApiUrl => str as ApiUrl;

type BuildApiEndpoint = Record<string, AnyFunction<any, ApiUrl>>;

const buildEndpoint = (baseURL = ""): BuildApiEndpoint => {
  const apiBase = `${baseURL}/api`;
  const integrationBase = `${apiBase}/integration`;

  return {
    archivedPhotoFiles: () => asApiUrl(`${apiBase}/appendice_photos_files/`),
    createResourceUrl: (resource: SyncedResourceName) =>
      asApiUrl(`${integrationBase}/create/${kebabCase(resource)}`),
    importPreviewUrl: (resourceHandle: SyncedResourceHandleKey) =>
      asApiUrl(`${integrationBase}/import-preview/${kebabCase(resourceHandle)}`),
    importResourceUrl: (resource: SyncedResourceName) =>
      asApiUrl(`${integrationBase}/import/${kebabCase(resource)}`),
    orderPhotoFiles: () => asApiUrl(`${apiBase}/order_photo_files/`),
    productGroupPhotoUrl: () => asApiUrl(`${apiBase}/productgroupphoto/`),
    productGroupUrl: () => asApiUrl(`${apiBase}/productgroups/`),
    productPhotoUrl: () => asApiUrl(`${apiBase}/productphoto/`),
    productUrl: () => asApiUrl(`${apiBase}/products/`),
    projectPhotoFiles: () => asApiUrl(`${apiBase}/project_photo_files/`),
    sendOrderUrl: () => asApiUrl(`${integrationBase}/send-order`),
    sendTasksUrl: () => asApiUrl(`${integrationBase}/send-tasks`),
    updateAndUploadResourceUrl: (resource: SyncedResourceName, id: string) =>
      asApiUrl(`${integrationBase}/update-and-upload/${kebabCase(resource)}/${id}`),
    updateResourceUrl: (resource: SyncedResourceName, id: string) =>
      asApiUrl(`${integrationBase}/update/${kebabCase(resource)}/${id}`),
  };
};

export const registerApiEndpoint = buildEndpoint();
export const getApiEndpoint = (baseUrl: string): BuildApiEndpoint => buildEndpoint(baseUrl);
