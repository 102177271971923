import {Project, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequestSpinner} from "@co-frontend-libs/components";
import {ResponseWithData} from "@co-frontend-libs/utils";
import {getApiEndpoint} from "api-endpoint-urls";
import {PhotosCard, PhotoTaskData} from "app-components";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useState} from "react";

interface ProjectPhotoAppendicesProps {
  project: Pick<Project, "url">;
}

export const ProjectPhotoAppendices = React.memo(function ProjectPhotoAppendices({
  project,
}: ProjectPhotoAppendicesProps): React.JSX.Element {
  const [photoTasks, setPhotoTasks] = useState<PhotoTaskData[] | null>(null);

  const handleSucces = useCallback((response?: ResponseWithData) => {
    if (response) {
      setPhotoTasks(response.data);
    }
  }, []);

  const data = {
    projectId: urlToId(project.url),
  };

  if (!photoTasks) {
    return (
      <div data-testid="loading">
        <AwaitingBackendRequestSpinner
          apiUrl={getApiEndpoint(globalConfig.baseURL).projectPhotoFiles()}
          data={data}
          execute={!photoTasks}
          method="POST"
          onSuccess={handleSucces}
        />
      </div>
    );
  }

  return (
    <div data-testid="project-photos">
      <PhotosCard data={photoTasks} />;
    </div>
  );
});
